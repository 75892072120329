:root {
  --main-blue: rgba(40, 169, 211, 1);
  --sec-blue: rgb(21, 181, 235);
  --alert-red: rgba(241, 65, 88, 1);
  --star-orange: rgb(255, 179, 0);
  --shadow: 0px 3px 6px rgba(0, 0, 0, 0.199);
}

body {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  scroll-behavior: smooth;
  user-select: none;
}
