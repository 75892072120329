div#wallpaper {
  background: rgb(27, 198, 255);
  background: radial-gradient(
    circle,
    rgb(62, 207, 255) 0%,
    rgba(0, 153, 204, 1) 100%
  );

  top: 50%;
  left: 50%;
  z-index: -5;
  width: 100%;
  height: 100%;
  position: fixed;
  transform: translate(-50%, -50%);
}
