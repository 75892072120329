.animationContainer{
    position: fixed;
    text-align: center;
    justify-content: center;
    left: 50%;
    top: 40%;
    transform: translate(-50%,-50%);
    
}

.loadingTitle{
    font-family: 'Roboto';
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    font-size: 5.4vw;
    justify-content: left;
    text-align: center;
    transform: translate(-50%);
}


@media screen and (min-width: 700px) {
    .loadingTitle {
       font-size: 30px;
    }
  }
  
