.homeContainer {
  margin: 0px;
  overflow-x: hidden;
  text-align: center;
  justify-content: center;
}

img#wallpaper {
  position: fixed;
  z-index: -5;
  width: 100vw;
  height: 100vh;
  transform: scale(1.3);
  margin: 0px;
  left: 0;
  overflow-x: hidden;
}

img#logo {
  position: absolute;
  left: 50%;
  top: 70vh;
  transform: translate(-50%, -30%);
  width: 80vw;
  max-width: 500px;
  transition: 0.5s;
}

.buttonsContainer {
  text-align: center;
  justify-content: end;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.qr-image-wrapper section {
  margin-top: 17vh;
  border-radius: 24px;
}

.qr-image-wrapper section div {
  box-shadow: unset !important;
}

.infoButtonContainer {
  position: absolute;
  right: 0;
  margin: 3vh;
  transform: scale(1.3);
}

.infoButtonIcon {
  cursor: pointer;
}

.infoQR {
  position: absolute;
  font-size: 1rem;
  left: 50%;
  transform: translate(-50%);
  text-align: center;
  color: rgba(15, 15, 15, 0.534);
  font-family: "Roboto";
  font-weight: bold;
}

@media screen and (min-width: 460px) {
  img#logo {
    margin-bottom: 10px;
    top: 65%;
    width: 20vw;
    max-width: 400px;
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.192));
  }
}


@media screen and (max-height: 500px) {
  img#logo {
    width: 40vh;
    transition: 0.5s;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 20%);
    filter: drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.192));
  }
  .infoQR{
    font-size: 1rem;
  }

}
