.animationTitle {
  font-family: "Roboto", sans-serif;
  color: #fff;
  bottom: 0;
  margin-top: 80vh;
  transition: 0.5s;
  font-size: calc(1.5em + 1vw);
  text-decoration: none;
}

.animationButton {
  outline: none;
  border: none;
  border-radius: 10px;
  background: #fff;
  padding: 10px;
  text-decoration: none;
  color: #000;
}

.animationContainer {
  left: 50%;
  transform: translate(-50%, -50%) scale(0.7);
}

.back-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 1rem;
  color: #eeee;
}
